import React from 'react'
import styled from 'styled-components'
import { gql } from '@apollo/client'
import { IMAGE_FRAGMENT } from '../../../fragments/image'
import { FullScreenCtaFragment } from './__generated__/fullScreenCta'
import { CTA as CTABase } from '../../../components/cta/cta'
import { Button } from '../../../components/button/button'
import { isNotNull } from '../../../utils/nonNull'

export type FullScreenCtaProps = {
  data: FullScreenCtaFragment[]
}

const StyledCTAButton = styled(Button)`
  padding: ${props => props.theme.typography.pxToRem(15)} ${props => props.theme.typography.pxToRem(30)} ${props => props.theme.typography.pxToRem(15)} ${props => props.theme.typography.pxToRem(30)};

  ${props => props.theme.breakpoints.up('sm')} {
    padding: ${props => props.theme.typography.pxToRem(23)} ${props => props.theme.typography.pxToRem(44)} ${props => props.theme.typography.pxToRem(24)} ${props => props.theme.typography.pxToRem(44)};
  }
`

export const FullScreenCta: React.FC<FullScreenCtaProps> = ({ data }) => {
  if (!data[0] || data[0].__typename !== 'fullScreenCta_BlockType') {
    return null
  }

  const { background, text, ctaLink } = data[0]

  return background?.[0]?.url ? (
    <CTABase imageURL={background[0].url}>
      <CTABase content>
        <CTABase heading>
          {text}
        </CTABase>
        <CTABase buttons>
          { ctaLink && ctaLink.__typename === 'linkField_Link' && isNotNull(ctaLink.url) && isNotNull(ctaLink.customText) && (
            <StyledCTAButton cta href={ctaLink.url || '#'}>{ctaLink.customText}</StyledCTAButton>
          )}
        </CTABase>
      </CTABase>
    </CTABase>
  ) : null
}

export const FULL_SCREEN_CTA_FRAGMENT = gql`
  ${IMAGE_FRAGMENT}
  
  fragment FullScreenCtaFragment on fullScreenCta_BlockType {
    text
    background {
      ...ImageFragment
    }
    ctaLink {
      url
      customText
      target
    }
  }
`